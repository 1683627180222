import React from 'react';
import { navigate } from 'gatsby';
import { withTrans } from '../i18n/withTrans';
import Layout from '../components/Layout/Layout';
import { Helmet } from 'react-helmet';
import Favicon from '../assets/images/favicon-manatour-16x16.png';
import { AiOutlineArrowLeft } from 'react-icons/ai';

const CGV = ({ t }) => {
  return (
    <Layout pageName="cgv">
      <Helmet>
        <title>{t('helmet.reservation_page')}</title>
        <link
          rel="icon"
          type="image/png"
          href={Favicon}
          sizes="16x16"
          className="favicon"
        />
      </Helmet>
      <div className="rgpd">
        <h1 className="h1-rgpd" onClick={() => navigate(-1)}>
          <AiOutlineArrowLeft className="title-arrow" />
          {t('cgv.title')}
        </h1>
        <h2>{t('cgv.title1')}</h2>
        <h4>{t('cgv.subTitle1')}</h4>
        <p style={{ whiteSpace: 'pre-line' }}>
          {t('cgv.reservationDescription1')}
        </p>
        <h4>{t('cgv.subTitle2')}</h4>
        <p style={{ whiteSpace: 'pre-line' }}>
          {t('cgv.reservationDescription2')}
        </p>

        <h2>{t('cgv.title2')}</h2>
        <h4>{t('cgv.subTitle1')}</h4>
        <p style={{ whiteSpace: 'pre-line' }}>{t('cgv.paymentDescription1')}</p>
        <h4>{t('cgv.subTitle2')}</h4>
        <p style={{ whiteSpace: 'pre-line' }}>{t('cgv.paymentDescription2')}</p>
        <h4>{t('cgv.subTitle3')}</h4>
        <p style={{ whiteSpace: 'pre-line' }}>{t('cgv.paymentDescription3')}</p>
        <h4>{t('cgv.subTitle4')}</h4>
        <p style={{ whiteSpace: 'pre-line' }}>{t('cgv.paymentDescription4')}</p>

        <h2>{t('cgv.title3')}</h2>
        <p style={{ whiteSpace: 'pre-line' }}>{t('cgv.updateDescription')}</p>
        <h3>{t('cgv.updateSubTitle1')}</h3>
        <h4>{t('cgv.subTitle1')}</h4>
        <p style={{ whiteSpace: 'pre-line' }}>{t('cgv.updateSub1-1')}</p>
        <h4>{t('cgv.subTitle2')}</h4>
        <p style={{ whiteSpace: 'pre-line' }}>{t('cgv.updateSub1-2')}</p>
        <h3>{t('cgv.updateSubTitle2')}</h3>
        <p style={{ whiteSpace: 'pre-line' }}>{t('cgv.updateSub2')}</p>

        <h2>{t('cgv.title4')}</h2>
        <p style={{ whiteSpace: 'pre-line' }}>{t('cgv.priceDescription')}</p>

        <h2>{t('cgv.title5')}</h2>
        <p style={{ whiteSpace: 'pre-line' }}>
          {t('cgv.insuranceDescription')}
        </p>

        <h2>{t('cgv.title6')}</h2>
        <p style={{ whiteSpace: 'pre-line' }}>
          {t('cgv.complaintDescription')}
        </p>

        <h2>{t('cgv.title7')}</h2>
        <p style={{ whiteSpace: 'pre-line' }}>{t('cgv.disputeDescription')}</p>
      </div>
    </Layout>
  );
};

export default withTrans(CGV);
